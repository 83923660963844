<template>
  <div class="login">
    <TextHead :title="'登陆/注册'" />
    <div class="login-bg">
      <div class="login-main w1200">
        <el-tabs type="border-card" class="login-box">
          <el-tab-pane label="账户注册">
            <el-form ref="registerForm" :model="registerForm">
              <el-form-item>
                <el-input v-model="registerForm.account" placeholder="请输入账号"></el-input>
              </el-form-item>
              <el-form-item>
                <el-input v-model="registerForm.password" placeholder="请输入密码"></el-input>
              </el-form-item>
              <el-form-item>
                <el-input v-model="registerForm.confirmPassword" placeholder="请确认密码"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="danger">账号注册</el-button>
              </el-form-item>
            </el-form>
          </el-tab-pane>
          <el-tab-pane label="账户登录">
            <el-form ref="loginForm" :model="loginForm">
              <el-form-item>
                <el-input v-model="loginForm.account" placeholder="请输入账号"></el-input>
              </el-form-item>
              <el-form-item>
                <el-input v-model="loginForm.password" placeholder="请输入密码"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="danger">账号登录</el-button>
              </el-form-item>
            </el-form>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>

    <MallFooter />
  </div>
</template>

<script>
import TextHead from "@/views/TextHead.vue";
import MallFooter from "@/views/MallFooter.vue";
export default {
  name: "Login",
  components: {
    TextHead,
    MallFooter,
  },
  data() {
    return {
      registerForm: {
        account: "",
        password: "",
        confirmPassword: "",
      },
      loginForm: {
        account: "",
        password: "",
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.login {
  background-color: #e9e7ee;
}

.login-bg {
  height: 480px;
  background-color: #e9e7ee;
  .login-main {
    height: 480px;
    background: url(../assets/img/login_bg.png) no-repeat;
    .login-box {
      float: right;
      width: 350px;
      margin-top: 85px;
      margin-right: 105px;
      ::v-deep .el-tabs__nav {
        width: 100%;
        .el-tabs__item {
          width: 50%;
          text-align: center;
          font-size: 16px;
        }
      }
      ::v-deep .el-tabs__content {
        padding: 35px 20px 15px;
      }
      ::v-deep .el-form-item__content {
        text-align: center;
      }
      .el-button {
        padding: 12px 120px;
        font-size: 16px;
      }
    }
  }
}
</style>
